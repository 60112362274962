<template>
  <content-with-sidebar class="blog-wrapper">

    <!-- blogs -->
    <b-row class="blog-list-wrapper">
      <b-card no-body class="col-md-12">
        <b-card-header class="pb-50">
          <h5>
            Filters
          </h5>
        </b-card-header>
        <b-card-body>
          <b-row>
            <b-col
                cols="12"
                md="6"
                class="mb-md-0 mb-2"
            >
              <label>Год</label>
              <v-select
                  v-model="year"
                  :options="yearOptions"
                  class="w-100"
                  :reduce="val => val.value"
                  :clearable="false"
              />
            </b-col>
            <b-col
                cols="12"
                md="6"
                class="mb-md-0 mb-2"
            >
              <label>Месяц</label>
              <v-select
                  v-model="month"
                  :options="monthOptions"
                  class="w-100"
                  :reduce="val => val.value"
                  :clearable="false"
              />
            </b-col>
          </b-row>
        </b-card-body>
      </b-card>

      <b-table
          ref="refMoviesListTable"
          :items="moviesList"
          responsive
          :fields="tableColumns"
          primary-key="kinopoiskId"
          show-empty
          empty-text="No matching records found"
          class="position-relative"
      >
        <!-- Column: Id -->
        <template #cell(id)="data">
          <b-link
              v-if="data.item.db"
              :to="{ name: 'movie-view', params: { id: data.item.db.id }}"
              class="font-weight-bold"
          >
            #{{ data.item.kinopoiskId }}
          </b-link>
          <span
            v-else
          >
            #{{ data.item.kinopoiskId }}
          </span>
        </template>

        <!-- Column: Client -->
        <template #cell(movie)="data">
          <b-media vertical-align="center">
            <template #aside>
              <b-avatar
                  size="32"
                  :src="data.item.posterUrlPreview"
                  :text="avatarText(data.item.nameRu)"
                  variant="primary"
              />
            </template>
            <span class="font-weight-bold d-block text-nowrap">
            {{ data.item.nameRu }} ({{ data.item.year }})
          </span>
            <small class="text-muted">{{ data.item.nameEn }}</small>
          </b-media>
        </template>

        <!-- Column: Issued Date -->
        <template #cell(premiereDate)="data">
          <span class="text-nowrap">
            {{ data.item.premiereRu }}
          </span>
        </template>

        <!-- Column: Issued Date -->
        <template #cell(rating)="data">
          <span class="font-weight-bold font-large-1 ml-1" v-if="data.item.db">
            <span
                :class="(data.item.db.rating_kp < 5) ? 'text-danger' : (data.item.db.rating_kp < 7) ? 'text-gray' : 'text-success'"
            >
              {{ data.item.db.rating_kp }}
            </span>
          </span>
        </template>

        <!-- Column: Issued Date -->
        <template #cell(votes)="data">
          <span class="text-nowrap mr-1" v-if="data.item.db">
             {{ data.item.db.votes_kp }}
          </span>
        </template>

        <!-- Column: Actions -->
        <template #cell(actions)="data">

          <div class="text-nowrap">
            <b-button
                v-if="!data.item.db"
                variant="success"
                class="btn-icon"
                size="sm"
                @click="addMovie(data.item)"
                :disabled="(disabled===data.item.kinopoiskId)"
            >
              <feather-icon icon="UserPlusIcon"/>
            </b-button>
            <b-button
                v-else
                :to="{name: 'movie-view', params: { id: data.item.db.id }}"
                variant="primary"
                class="btn-icon"
                size="sm"
            >
              <feather-icon icon="ArrowRightCircleIcon"/>
            </b-button>
            <b-button
                :href="`https://kinopoisk.ru/film/${data.item.kinopoiskId}`"
                variant="warning"
                class="btn-icon ml-1"
                size="sm"
            >
              <feather-icon icon="CastIcon"/>
            </b-button>
          </div>
        </template>
      </b-table>
    </b-row>

    <!--/ blogs -->

  </content-with-sidebar>
</template>

<script>
import {
  BRow,
  BCol,
  BCard,
  BFormInput,
  BCardText,
  BCardTitle,
  BMedia,
  BAvatar,
  BMediaAside,
  BMediaBody,
  BImg,
  BCardBody,
  BLink,
  BBadge,
  BFormGroup,
  BInputGroup,
  BInputGroupAppend,
  BPagination,
  BCardHeader,
  BTable,
  BButton,
} from 'bootstrap-vue'
import {avatarText, kFormatter} from '@core/utils/filter'
import ContentWithSidebar from '@core/layouts/components/content-with-sidebar/ContentWithSidebar.vue'
import store from "@/store";
import {onUnmounted, ref, watch} from "@vue/composition-api";
import useMoviesList from "./useMoviesList";
import movieStoreModule from "@/views/database/movieStoreModule";
import vSelect from "vue-select";
import router from "@/router";

export default {
  components: {
    BRow,
    BCol,
    BCard,
    BFormInput,
    BCardText,
    BCardBody,
    BCardTitle,
    BMedia,
    BAvatar,
    BMediaAside,
    BMediaBody,
    BLink,
    BBadge,
    BFormGroup,
    BInputGroup,
    BInputGroupAppend,
    BImg,
    BPagination,
    BCardHeader,
    BTable,
    BButton,
    ContentWithSidebar,
    vSelect,
  },
  data() {
    return {
      blogSidebar: {},
      disabled: 0,
    }
  },
  methods: {
    kFormatter,
    addMovie(movie) {
      this.disabled = movie.filmId
      this.$http.post(`/movie`, {kp_id: movie.kinopoiskId, 'title_ru': movie.nameRu}).then(res => {
          if (res.data.data) {
            this.fetchMoviesList()
          }
      })
    },
  },
  setup() {
    const STORE_MODULE_NAME = 'movies'

    // Register module
    if (!store.hasModule(STORE_MODULE_NAME)) store.registerModule(STORE_MODULE_NAME, movieStoreModule)

    // UnRegister on leave
    onUnmounted(() => {
      if (store.hasModule(STORE_MODULE_NAME)) store.unregisterModule(STORE_MODULE_NAME)
    })

    const year = ref(2023)
    const month = ref('SEPTEMBER')

    const yearOptions = [
      { label: '2025', value: '2025' },
      { label: '2024', value: '2024' },
      { label: '2023', value: '2023' },
      { label: '2022', value: '2022' },
      { label: '2021', value: '2021' },
      { label: '2020', value: '2020' },
      { label: '2019', value: '2019' },
    ]

    const monthOptions = [
      { label: 'Январь', value: 'JANUARY' },
      { label: 'Февраль', value: 'FEBRUARY' },
      { label: 'Март', value: 'MARCH' },
      { label: 'Апрель', value: 'APRIL' },
      { label: 'Май', value: 'MAY' },
      { label: 'Июнь', value: 'JUNE' },
      { label: 'Июль', value: 'JULY' },
      { label: 'Август', value: 'AUGUST' },
      { label: 'Сентябрь', value: 'SEPTEMBER' },
      { label: 'Октябрь', value: 'OCTOBER' },
      { label: 'Ноябрь', value: 'NOVEMBER' },
      { label: 'Декабрь', value: 'DECEMBER' },
    ]

    // Table Handlers
    const tableColumns = [
      { key: 'id', label: '#', sortable: false },
      { key: 'movie', sortable: false },
      { key: 'rating', sortable: false },
      { key: 'votes', sortable: false },
      { key: 'premiereDate', sortable: false },
      { key: 'actions' },
    ]

    const moviesList = ref([])
    const fetchMovies = (...args) => store.dispatch('movies/fetchMoviesPremiers', ...args)

    const fetchMoviesList = () => {
      fetchMovies({
        year: year.value,
        month: month.value,
      })
      .then(response => {
        moviesList.value = response.data
      })
    }

    fetchMoviesList()

    watch([year, month], () => {
      fetchMoviesList()
    }, {
      deep: true,
    })

    const {
      perPage,
      currentPage,
      totalMovies,
      dataMeta,
      perPageOptions,
      searchQuery,
      isSortDirDesc,
      refMovieListTable,
    } = useMoviesList()

    return {
      fetchMovies,
      tableColumns,
      perPage,
      currentPage,
      totalMovies,
      dataMeta,
      perPageOptions,
      searchQuery,
      isSortDirDesc,
      refMovieListTable,
      moviesList,
      monthOptions,
      yearOptions,
      year,
      month,
      avatarText,
      fetchMoviesList,
    }
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/pages/page-blog.scss';
</style>
